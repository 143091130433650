import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import { useState, useEffect } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import clsx from "clsx"

export default function Blogs({ data }) {
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useQueryParam("category", StringParam)
  useEffect(() => {
    const initCategories = []
    data.allMarkdownRemark.edges.forEach((element) => {
      const thisCategory = element.node.frontmatter.category
      if (!initCategories.includes(thisCategory))
        initCategories.push(thisCategory)
    })
    initCategories.sort()
    setCategories(initCategories)
  }, [data.allMarkdownRemark.edges])
  return (
    <Layout>
      <div className=" pt-20 max-w-5xl px-8 md:px-16 mx-auto flex">
        <div className="flex-auto w-1/4 md:block pr-8">
          <p className="text-lg font-medium font-display mb-2">Category</p>
          <button
            className={clsx(
              "block font-display",
              category === undefined && "text-blue-600"
            )}
          >
            <Link to="/blogs">All</Link>
          </button>
          {categories.map((c) => {
            return (
              <button
                key={`category-btn-${c}`}
                className={clsx(
                  "block font-display",
                  category === c && "text-blue-600"
                )}
                onClick={() => setCategory(c)}
              >
                {c}
              </button>
            )
          })}
        </div>
        <div className="flex-auto w-3/4">
          {data.allMarkdownRemark.edges
            .filter(({ node }) => {
              if (category === undefined) return true
              return node.frontmatter.category === category
            })
            .map(({ node }) => {
              return (
                <div className="mb-16 " key={`blog-${node.id}`}>
                  <p className="text-xl font-bold font-display">
                    <Link to={node.frontmatter.slug} key={node.id}>
                      {node.frontmatter.title}
                    </Link>
                  </p>
                  <p className="text-sm text-gray-600 mt-2">{node.excerpt}</p>
                  <div className="flex items-center mt-2">
                    <p className="text-xs text-gray-400">
                      {node.frontmatter.date}
                    </p>
                    {node.frontmatter.category && (
                      <button
                        className="ml-2 flex items-center text-xs bg-slate-200 h-5 rounded-xl px-2 font-semibold text-slate-600"
                        onClick={() => setCategory(node.frontmatter.category)}
                      >
                        {node.frontmatter.category}
                      </button>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blogs)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            type
            slug
            category
          }
          excerpt
        }
      }
    }
  }
`
